import { Route } from 'vue-router'

export interface QueryParamAction {
  activationFunc: (route: Route) => boolean
  resultFunc: (route: Route, app: any) => Promise<void>
  name: string // Name to allow easier debugging
}

const queryParamActions: QueryParamAction[] = [
  {
    activationFunc: (route) => {
      return Boolean(route.query.workspace_settings)
    },
    resultFunc: async (route, app) => {
      const workspaceId = route.query.workspace_settings
      const workspaceTab = route.query.workspace_settings_tab
      app.$modals.workspace.openSettings(
        app,
        await app.$store.dispatch('getWorkspace', { id: workspaceId }),
        workspaceTab
      )
      app.$router.push({
        ...app.$route,
        query: {
          ...app.$route.query,
          workspace_settings: undefined,
          workspace_settings_tab: undefined
        }
      })
    },
    name: 'Open Workspace Settings Modal'
  },
  {
    activationFunc: (route) => {
      return Boolean(route.query['profile_settings'])
    },
    resultFunc: async (_, app) => {
      app.$modals.user.openProfileSettings(app, app.currentUser)
    },
    name: 'Open Current User Profile'
  }
]

export default queryParamActions
export function doQueryParamActions(route: Route, app: any) {
  for (const queryParamAction of queryParamActions) {
    if (queryParamAction.activationFunc(route)) {
      app.$console.debug(
        `[Query Param Action] Query params matched for action '${queryParamAction.name}'`
      )
      queryParamAction.resultFunc(route, app)
    }
  }
}
