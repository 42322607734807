<template>
  <div ref="workspacelist" class="select-workspaces">
    <div class="select-workspaces-search">
      <TextInput
        v-model="query"
        left-icon="search"
        placeholder="Find workspaces"
      />
    </div>
    <div class="select-workspaces-list">
      <router-link
        v-for="ws in filteredWorkspaces"
        :key="ws.uuid"
        :to="workspaceRoute(ws)"
      >
        <p class="select-workspaces-list-workspace" @click="hideWorkspaceList">
          <img
            :src="ws.image || require('@/assets/icons/globe.svg')"
            alt=""
            class="select-workspaces-list-workspace-icon"
            :class="{ 'has-image': !!ws.image }"
          />
          {{ ws.name }}
        </p>
      </router-link>
    </div>
    <router-link
      :to="{
        name: 'workspace-list'
      }"
    >
      <div class="select-workspaces-overview" @click="hideWorkspaceList">
        <img
          src="@/assets/view_type/view-list.svg"
          alt=""
          class="select-workspaces-overview-icon"
        />
        Workspace list
      </div>
    </router-link>
  </div>
</template>

<script>
import TextInput from '@c/library/TextInput.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'WorkspaceSelect',
  components: {
    TextInput
  },
  data: () => ({
    query: ''
  }),
  computed: {
    ...mapGetters(['workspaces']),
    filteredWorkspaces() {
      return this.workspaces.filter(ws =>
        ws.name.toLowerCase().includes(this.query.toLowerCase())
      )
    }
  },
  mounted() {
    document.addEventListener('click', this.checkClickOutside)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.checkClickOutside)
  },
  methods: {
    hideWorkspaceList() {
      this.$emit('hide')
    },
    workspaceRoute(ws) {
      return {
        name: 'home',
        params: { workspace_id: ws.uuid },
        query: {}
      }
    },
    checkClickOutside(e) {
      if (!this.$refs.workspacelist.contains(e.target)) this.$emit('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
.select-workspaces {
  position: absolute;
  top: 1rem;
  left: calc(100% + 0.5rem);
  width: min(25rem, 90vw);
  background: white;
  border-radius: 4px;
  border: 1px solid rgba(#000, 0.08);
  box-shadow: 0 0 8px rgba(#000, 0.1);
  z-index: 100;

  &-list {
    display: flex;
    flex-flow: column nowrap;
    padding: 0.5rem 0;
    max-height: 50vh;
    overflow-y: auto;

    &-workspace {
      cursor: pointer;
      padding: 0.5rem 1rem;
      white-space: nowrap;
      transition: background 0.2s;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;

      &:hover {
        background: rgba(#000, 0.08);
      }

      &-icon {
        height: 1.2rem;
        border-radius: 2px;

        &:not(.has-image) {
          opacity: 0.5;
        }
      }
    }
  }

  &-search {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid rgba(#000, 0.08);
  }

  &-overview {
    cursor: pointer;
    padding: 0.75rem 1rem;
    white-space: nowrap;
    transition: background 0.2s;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    border-top: 1px solid rgba(#000, 0.08);
    font-weight: 700;

    &:hover {
      background: rgba(#000, 0.08);
    }

    &-icon {
      height: 1.2rem;
    }
  }
}
</style>
