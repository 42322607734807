<template>
  <div
    class="document-type-tabs border subtle-primary no-top no-horizontal thick"
    :class="{ unclickable: sendingEvent }"
  >
    <b-tabs v-model="tab" position="is-left" class="block">
      <b-tab-item value="all">
        <template #header>
          <div id="allresultstab" class="tab-wrapper">
            <img src="@/assets/icons/search.svg" class="tab-icon" />
            <span class="has-text-weight-bold has-text-black">All results</span>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item value="documents">
        <template #header>
          <div id="docstab" class="tab-wrapper">
            <img src="@/assets/icons/document.svg" class="tab-icon" />
            <span class="has-text-weight-bold has-text-black">Docs</span>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item value="sheets">
        <template #header>
          <div id="sheetstab" class="tab-wrapper">
            <img src="@/assets/icons/sheet.svg" class="tab-icon" />
            <span class="has-text-weight-bold has-text-black">Sheets</span>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item value="presentations">
        <template #header>
          <div id="presentationstab" class="tab-wrapper">
            <img src="@/assets/icons/presentation.svg" class="tab-icon" />
            <span class="has-text-weight-bold has-text-black">
              Presentations
            </span>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item value="slides">
        <template #header>
          <div id="slidestab" class="tab-wrapper">
            <img src="@/assets/icons/slides.svg" class="tab-icon" />
            <span class="has-text-weight-bold has-text-black">Slides</span>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item value="videos">
        <template #header>
          <div id="videotab" class="tab-wrapper">
            <img src="@/assets/icons/video.svg" class="tab-icon" />
            <span class="has-text-weight-bold has-text-black">Videos</span>
          </div>
        </template>
      </b-tab-item>
      <b-tab-item v-if="showPeopleTab" value="people">
        <template #header>
          <div id="peopletab" class="tab-wrapper">
            <img src="@/assets/icons/users.svg" class="tab-icon" />
            <span class="has-text-weight-bold has-text-black">People</span>
          </div>
        </template>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import {
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ResourceTypeTabs',

  data() {
    return {
      tab: this.$route.params.tab || 'all',
      availableTabs: [
        'all',
        'documents',
        'presentations',
        'slides',
        'videos',
        'sheets'
      ],
      backendMapping: {
        all: 'all',
        documents: 'document',
        sheets: 'sheet',
        presentations: 'presentation',
        slides: 'slides',
        snippets: 'snippets',
        people: 'people',
        videos: 'video'
      },
      keepQuery: false
    }
  },
  computed: {
    ...mapGetters([
      'showPeopleTab',
      'currentWorkspace',
      'availableViews',
      'sendingEvent'
    ]),
    routeTab() {
      return this.$route.params.workspace_id
    }
  },
  watch: {
    tab() {
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.tab_change
      })
      this.setTab()
    },
    routeTab: {
      deep: true,
      handler() {
        this.fillAvailableTabs()
        this.keepQuery = true
        this.tab = this.$route.params.tab || 'all'
      }
    }
  },
  async mounted() {
    this.setCurrentWorkspace(this.$route.params.workspace_id)
    this.fillAvailableTabs()
    this.$nextTick(() => {
      this.keepQuery = true
      this.setTab()
    })
  },
  beforeDestroy() {
    this.setResourceTypeFilter('all')
  },
  methods: {
    ...mapActions([
      'setResourceTypeFilter',
      'setCurrentWorkspace',
      'setSearchEnrichmentContext'
    ]),
    setTab() {
      if (!this.availableTabs.includes(this.tab)) this.tab = 'all'
      this.setResourceTypeFilter(this.backendMapping[this.tab])

      let routeParams = this.$route.params
      if (this.tab === 'all') {
        delete routeParams['tab']
      } else {
        routeParams = {
          ...routeParams,
          tab: this.tab
        }
      }
      this.$router
        .push({
          name: this.$route.name,
          params: routeParams,
          query: {
            ...(this.$route.query.query
              ? { query: this.$route.query.query }
              : {}),
            ...(this.keepQuery ? this.$route.query : {}),
            ...(this.availableViews.includes(this.$route.query.view)
              ? { view: this.$route.query.view }
              : {})
          }
        })
        .catch(() => {})
      this.keepQuery = false
    },
    fillAvailableTabs() {
      if (this.showPeopleTab && !this.availableTabs.includes('people'))
        this.availableTabs.push('people')
    }
  }
}
</script>

<style scoped lang="scss">
.document-type-tabs {
  ::v-deep .tab-content {
    padding: 0;
  }
  ::v-deep .tabs {
    ul {
      border: none;
      padding: 0 2.5rem;
    }
    li a {
      color: black;
      border: none;
    }
    li.is-active a,
    li:hover a {
      color: #2684ff !important;
      border-color: #2684ff !important;
      border-bottom: 4px solid;
    }
  }

  &.unclickable {
    pointer-events: none !important;
  }
}

.tab {
  &-icon {
    height: 1.5rem;
  }

  &-wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
