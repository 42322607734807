<template>
  <div
    v-show="(similarQueries || []).length > 0"
    id="otherpeoplesearchfor"
    class="queries"
  >
    <p class="queries-title">Other people search for</p>
    <router-link
      v-for="query in similarQueries"
      :key="query"
      :to="route(query)"
    >
      <div class="queries-query" @click="() => enrichSearchContext()">
        {{ query }}
      </div>
    </router-link>
  </div>
</template>

<script>
import {
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SimilarQueries',
  computed: {
    ...mapState({
      similarQueries: (state) => state.filters.similarQueries
    })
  },
  methods: {
    ...mapActions(['setSearchEnrichmentContext']),
    route(query) {
      return {
        ...this.$route,
        query: {
          query: query
        }
      }
    },
    enrichSearchContext() {
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.similar_query
      })
    }
  }
}
</script>

<style scoped lang="scss">
.queries {
  width: 100%;
  height: max-content;
  background: #f9f9fa;
  border-radius: 4px;
  border: 1px solid rgba(#000, 8%);
  padding: 2rem 1.5rem 2rem 1.5rem;

  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;

  &-title {
    width: 100%;
    font-size: 1.3rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
  }

  &-query {
    padding: 0.3rem 1rem;
    border-radius: 6px;
    background: #f1f2f3;
    color: #8f9399;
    font-weight: 600;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &:hover {
      background: #ebebeb;
    }
  }
}
</style>
