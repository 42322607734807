var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "impersonationmenu", staticClass: "impersonate" },
    [
      _vm.impersonatedMember
        ? _c(
            "b-tooltip",
            {
              attrs: {
                active: !_vm.visible,
                label:
                  "Impersonating " +
                  _vm.$umodel.full_name(_vm.impersonatedMember),
                position: "is-right",
                type: "is-dark"
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "impersonate-trigger",
                  on: { click: _vm.toggleMenu }
                },
                [
                  _c("Avatar", {
                    attrs: {
                      user: _vm.impersonatedMember,
                      "fallback-icon": "user",
                      "filter-fallback": {},
                      size: "xs"
                    }
                  })
                ],
                1
              )
            ]
          )
        : _vm._e(),
      _vm.visible
        ? _c("div", { staticClass: "impersonate-content" }, [
            _c("div", { staticClass: "impersonate-content-header" }, [
              _c("p", { staticClass: "impersonate-content-header-title" }, [
                _vm._v("Impersonating")
              ]),
              _c(
                "div",
                { staticClass: "impersonate-content-header-avatar" },
                [
                  _c("Avatar", {
                    attrs: {
                      user: _vm.impersonatedMember,
                      "fallback-icon": "user",
                      "filter-fallback": {},
                      size: "s"
                    }
                  }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.$umodel.full_name(_vm.impersonatedMember)) +
                      " "
                  ),
                  _c(
                    "span",
                    { staticClass: "impersonate-content-header-avatar-email" },
                    [_vm._v(_vm._s(_vm.$umodel.email(_vm.impersonatedMember)))]
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "impersonate-content-actions" },
              [
                _c("Button", {
                  attrs: { text: "Change user", type: "white" },
                  on: { click: _vm.openModal }
                }),
                _c("Button", {
                  attrs: { text: "Stop impersonation" },
                  on: {
                    click: function() {
                      return _vm.impersonate(undefined)
                    }
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "b-modal",
        {
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "impersonate-modal" }, [
            _c(
              "div",
              { staticClass: "impersonate-modal-header" },
              [
                _vm._v(" Change impersonated user "),
                _c("Button", {
                  attrs: { icon: "close", type: "grey" },
                  on: { click: _vm.closeModal }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "impersonate-modal-content" },
              [
                _c("TextInput", {
                  attrs: {
                    "left-icon": "search",
                    placeholder: "Find member to impersonate"
                  },
                  on: { submit: _vm.search },
                  model: {
                    value: _vm.query,
                    callback: function($$v) {
                      _vm.query = $$v
                    },
                    expression: "query"
                  }
                }),
                _c(
                  "div",
                  { staticClass: "impersonate-modal-content-list" },
                  _vm._l(_vm.options, function(option) {
                    return _c(
                      "div",
                      {
                        key: option.uuid,
                        staticClass: "impersonate-modal-content-list-option"
                      },
                      [
                        _c("Avatar", { attrs: { user: option, size: "xs" } }),
                        _vm._v(
                          " " + _vm._s(_vm.$umodel.full_name(option)) + " "
                        ),
                        _c("Button", {
                          staticClass:
                            "impersonate-modal-content-list-option-button",
                          attrs: {
                            text: "Impersonate",
                            disabled:
                              (!!_vm.impersonatedMember &&
                                option.uuid === _vm.impersonatedMember.uuid) ||
                              (!!_vm.currentWorkspaceMember &&
                                option.uuid === _vm.currentWorkspaceMember.uuid)
                          },
                          on: {
                            click: function() {
                              return _vm.impersonate(option)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }