
































import { doQueryParamActions } from '@/router/actions/global-actions'
import { getQualityLabels } from '@/services/qualityLabelService'
import DefaultApplicationShell from '@c/features/app/pages/DefaultApplicationShell.vue'
import { PERMISSION } from '@c/models/Workspace'
import { removeImpersonatedMember } from '@c/util'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'HydratedApplicationShell',
  components: { DefaultApplicationShell },

  props: {
    currentWorkspaceId: {
      type: String,
      default: undefined
    },

    currentWorkspaceMemberId: {
      type: String,
      default: undefined
    },

    activeFeedItem: {
      type: Number,
      default: undefined
    },

    hasContentBlock: {
      type: Boolean,
      default: false
    },

    noPadding: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      searchResults: {},
      activeWorkspace: this.workspaceMap
        ? this.workspaceMap[
            this.$route?.query?.workspace || this.currentWorkspaceId
          ]
        : undefined,
      lastQuery: '',
      initialLoad: true
    }
  },

  computed: {
    ...mapState({
      workspaces: state => Object.values(state.workspaces.workspaces),
      workspaceMap: state => state.workspaces.workspaces,

      activeMember: function(state) {
        try {
          return state.workspaces.workspaces[
            this.activeWorkspace.uuid
          ].members.find(x => x.uuid === this.currentWorkspaceMemberId)
        } catch (e) {
          return undefined
        }
      },

      currentUser: state => state.users.currentUser,
      onboardingNeeded: state => state.authentication.onboardingNeeded
    }),
    ...mapGetters([
      'availableViews',
      'currentWorkspace',
      'currentWorkspaceMembers',
      'canReadOri',
      'canWriteOri'
    ]),
    currentWorkspaceMember() {
      return this.currentWorkspaceMembers[this.$route.params.workspace_id]
    },
    isHomePage() {
      return this.$route.name === 'home'
    },
    populatedWorkspaces() {
      return this.workspaces.map(ws => ({
        ...ws,
        sidebarSelected: this.currentWorkspaceId === ws.uuid
      }))
    },

    populatedActiveWorkspace() {
      return {
        ...this.activeWorkspace
      }
    },

    canUpdateLabels() {
      return this.currentPermissions.includes(PERMISSION.WORKSPACE_LABELS_WRITE)
    },

    currentPermissions() {
      return this.currentWorkspaceMember?.permissions || []
    },

    workspaceFromRoute() {
      return this.$route.params.workspace_id
    }
  },

  watch: {
    currentWorkspaceId(newVal, oldVal) {
      if (newVal !== oldVal && newVal) {
        this.activeWorkspace = this.workspaceMap[this.currentWorkspaceId]
        this.resetSlides()
        this.resetTemplates()
        this.resetSalesKits()
        this.clearCurrentCollection()
      }
    },

    async workspaces(newVal, oldVal) {
      if (oldVal && newVal !== oldVal) {
        await this.updateActiveWorkspace()
      } else if (newVal !== oldVal) {
        await this.updateActiveWorkspace(true)
      }
    },

    workspaceFromRoute() {
      this.checkCurrentWorkspace()
    }
  },

  async created() {
    if (this.onboardingNeeded) {
      this.$router.push({ name: 'onboarding' })
    }
    doQueryParamActions(this.$route, this)
  },

  async mounted() {
    await this.updateActiveWorkspace(true)
    this.checkCurrentWorkspace()
  },

  methods: {
    ...mapActions([
      'searchResources',
      'setCurrentWorkspace',
      'getCurrentWorkspaceMember',
      'logout',
      'setQualityLabels',
      'resetSearchInsights',
      'resetSlides',
      'resetTemplates',
      'resetSalesKits',
      'clearCurrentCollection',
      'getAttributes',
      'getUserIntegrations',
      'getWorkspaceMemberIntegrations'
    ]),
    async checkCurrentWorkspace() {
      if (
        !this.currentWorkspace ||
        this.currentWorkspace.uuid !== this.$route.params.workspace_id
      )
        this.setCurrentWorkspace(this.$route.params.workspace_id)

      if (!this.currentWorkspaceMember) {
        await this.getCurrentWorkspaceMember({
          workspace_id: this.$route.params.workspace_id
        })
      }
      this.getLabels(this.currentWorkspace)
      this.getWorkspaceMemberIntegrations({
        workspace_id: this.$route.params.workspace_id,
        member_id: this.currentWorkspaceMember.uuid
      })
    },
    async updateActiveWorkspace(isMounting = false) {
      this.$set(
        this,
        'activeWorkspace',
        this.workspaceMap[
          this.$route?.query?.workspace || this.currentWorkspaceId
        ]
      )
      if (
        this.activeWorkspace === undefined ||
        !this.populatedWorkspaces
          .map(x => x.uuid)
          .includes(this.activeWorkspace.uuid) ||
        !this.populatedWorkspaces
          .map(x => x.uuid)
          .includes(this.activeWorkspace.uuid)
      ) {
        const lastWorkspace = window.localStorage.getItem('lastWorkspace')
        // User either has workspaces
        if (this.populatedWorkspaces.length > 0) {
          // Find the last workspace or take the first
          const hasLastWorkspace = this.populatedWorkspaces.find(
            x => x.uuid === lastWorkspace || x.workspace === lastWorkspace
          )
          this.$router
            .push({
              ...this.$route,
              params: {
                ...this.$route.params,
                workspace_id: hasLastWorkspace
                  ? lastWorkspace
                  : this.populatedWorkspaces[0].uuid
              },
              query: {
                ...this.$route.query,
                view: this.availableViews.includes(this.$route.query.view)
                  ? this.$route.query.view
                  : undefined
              }
            })
            .catch(() => {})
        } else {
          if (this.currentUser) {
            // Else go to the workspace list
            this.$router.push({
              name: 'workspace-list'
            })
            // this.$toast.danger(
            //   'No workspaces found',
            //   'You are currently not part of any workspace. Join one or create one first.'
            // )
          }
        }
      } else {
        if (!isMounting) {
          await this.workspaceChanged(this.activeWorkspace, false)
        }
      }
    },

    async workspaceChanged(ws, reroute = true) {
      this.activeWorkspace = ws
      window.localStorage.setItem('lastWorkspace', ws.uuid)
      if (!this.initialLoad && !!this.$route?.query?.act_as) {
        removeImpersonatedMember(this.$route, this.$router)
      } else {
        this.initialLoad = false
        return
      }
      if (reroute) {
        try {
          await this.$router.push({
            name: 'home',
            params: {
              ...this.$router.params,
              workspace_id: ws.uuid
            }
          })
        } catch (e) {
          this.$console.debug(e)
        }
      }
    },

    logoutDialog() {
      const that = this
      this.$dialogs.user.logoutDialog(this, function() {
        that.logout()
        this.$emit('close')
        that.$router.push('/login')
      })
    },

    async acceptInvite(workspace) {
      try {
        await this.joinWorkspace({ workspace })
        this.$toast.success(
          'Invitation accepted',
          `You are now a member of ${workspace.name}.`
        )
      } catch (e) {
        this.$console.debug(e)
        this.$toast.error(e, 'joining workspace')
      }
    },
    async getLabels(workspace) {
      try {
        if (this.canUpdateLabels) {
          const labels = await getQualityLabels(workspace.uuid)
          this.setQualityLabels(labels)
        }
        if (this.canReadOri || this.canWriteOri) {
          this.getAttributes({ workspace_id: workspace?.uuid, force: true })
        }
        this.getUserIntegrations()
      } catch (e) {
        this.$console.debug(e)
      }
    }
  }
}
