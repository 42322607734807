var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "u-columns",
        staticStyle: { "max-width": "100vw", height: "100vh" }
      },
      [
        _c("div", { staticClass: "u-column is-narrow sidebar-container" }, [
          _c(
            "div",
            {
              staticClass: "fit sidebar-wrapper",
              style: "--sidebar-width: " + _vm.sidebarWidth + "px",
              attrs: { id: "sidebar" }
            },
            [
              _c("SegmentedWebAppSidebar", {
                attrs: { workspace: _vm.workspaceContext }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          {
            staticClass: "u-column bg-container",
            staticStyle: { "max-width": "100vw", height: "100vh" }
          },
          [
            _c("div", { class: _vm.workspaceGridLayout }, [
              _vm.showHeader
                ? _c("div", { staticClass: "grid-layout-header" }, [
                    _c(
                      "div",
                      { staticClass: "title-header-wrapper" },
                      [
                        _c(
                          "div",
                          { staticClass: "title-header" },
                          [
                            _c("p", { staticClass: "title-header-title" }, [
                              _vm._v(_vm._s(_vm.headerTitle))
                            ]),
                            _vm.showSearchNavigation
                              ? _c("GlobalSearchBar", {
                                  staticClass: "title-header-search-bar"
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.isSearch
                          ? _c("ResourceTypeTabs", {
                              staticClass: "title-header-search-tabs"
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  class: {
                    "full-width": true,
                    "u-columns": true,
                    "grid-layout-content": true,
                    "content-container": !(
                      _vm.isHomePage || _vm.$route.meta.noscroll
                    ),
                    "content-container-home": _vm.isHomePage
                  },
                  style: _vm.scrollContainerStyling,
                  attrs: { id: "scroll-container" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "u-column full-height fit-width search-content"
                    },
                    [
                      _vm._t("default", null, {
                        workspace: _vm.workspaceContext,
                        user: _vm.user
                      })
                    ],
                    2
                  ),
                  _vm.showKnowledgePanel
                    ? _c(
                        "div",
                        { staticClass: "kpanel-container full-height" },
                        [
                          _vm.knowledgePanelEnabled
                            ? _c("KnowledgePanel", {
                                key: _vm.$route.fullPath,
                                attrs: {
                                  insights: _vm.insights,
                                  entities: _vm.extractedEntities
                                }
                              })
                            : _vm._e(),
                          _c("SimilarQueries")
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          ]
        ),
        _vm._t("modals", null, {
          workspace: _vm.workspaceContext,
          user: _vm.user
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }