var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "workspacelist", staticClass: "select-workspaces" },
    [
      _c(
        "div",
        { staticClass: "select-workspaces-search" },
        [
          _c("TextInput", {
            attrs: { "left-icon": "search", placeholder: "Find workspaces" },
            model: {
              value: _vm.query,
              callback: function($$v) {
                _vm.query = $$v
              },
              expression: "query"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "select-workspaces-list" },
        _vm._l(_vm.filteredWorkspaces, function(ws) {
          return _c(
            "router-link",
            { key: ws.uuid, attrs: { to: _vm.workspaceRoute(ws) } },
            [
              _c(
                "p",
                {
                  staticClass: "select-workspaces-list-workspace",
                  on: { click: _vm.hideWorkspaceList }
                },
                [
                  _c("img", {
                    staticClass: "select-workspaces-list-workspace-icon",
                    class: { "has-image": !!ws.image },
                    attrs: {
                      src: ws.image || require("@/assets/icons/globe.svg"),
                      alt: ""
                    }
                  }),
                  _vm._v(" " + _vm._s(ws.name) + " ")
                ]
              )
            ]
          )
        }),
        1
      ),
      _c(
        "router-link",
        {
          attrs: {
            to: {
              name: "workspace-list"
            }
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "select-workspaces-overview",
              on: { click: _vm.hideWorkspaceList }
            },
            [
              _c("img", {
                staticClass: "select-workspaces-overview-icon",
                attrs: {
                  src: require("@/assets/view_type/view-list.svg"),
                  alt: ""
                }
              }),
              _vm._v(" Workspace list ")
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }