<template>
  <div ref="usermenucontent" class="usermenu">
    <div class="usermenu-trigger" @click="toggleMenu">
      <Avatar
        :user="currentUser"
        fallback-icon="user"
        :filter-fallback="{}"
        size="s"
      />
    </div>
    <div v-if="visible" class="usermenu-content">
      <div class="usermenu-content-items">
        <p class="usermenu-content-items-item" @click="gotoSettings">
          <img
            src="@/assets/icons/settings.svg"
            alt=""
            class="usermenu-content-items-item-icon"
          />
          My settings
        </p>
        <router-link :to="profileRoute">
          <p class="usermenu-content-items-item" @click="closeMenu">
            <img
              src="@/assets/icons/user.svg"
              alt=""
              class="usermenu-content-items-item-icon"
            />
            My profile
          </p>
        </router-link>
        <p class="usermenu-content-items-item" @click="handleLogout">
          <img
            src="@/assets/icons/logout.svg"
            alt=""
            class="usermenu-content-items-item-icon"
          />
          Log out
        </p>
      </div>
      <div class="usermenu-content-footer">
        <span
          >Logged in as
          <span class="bold">{{ $umodel.full_name(currentUser) }}</span></span
        >
        <span>{{ $umodel.email(currentUser) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Avatar from '@c/library/Avatar.vue'

export default {
  name: 'UserMenu',
  components: {
    Avatar
  },
  data: () => ({
    visible: false
  }),
  computed: {
    ...mapGetters(['currentUser']),
    profileRoute() {
      return {
        name: 'member-profile',
        params: {
          workspace_id: this.$route.params.workspace_id,
          member_id: 'me'
        }
      }
    }
  },
  methods: {
    ...mapActions(['logout']),
    toggleMenu() {
      if (this.visible) this.closeMenu()
      else this.openMenu()
    },
    openMenu() {
      this.visible = !this.visible
      document.addEventListener('click', this.checkClickOutside)
    },
    checkClickOutside(e) {
      if (
        this.visible &&
        this.$refs.usermenucontent &&
        !this.$refs.usermenucontent.contains(e.target)
      ) {
        this.closeMenu()
      }
    },
    closeMenu() {
      this.visible = false
      document.removeEventListener('click', this.checkClickOutside)
    },
    gotoSettings() {
      this.$modals.user.openProfileSettings(this, this.user)
      this.closeMenu()
    },
    handleLogout() {
      this.$dialogs.user.logoutDialog(this, () => {
        this.logout()
        this.$router.push({ name: 'login' })
        return true
      })
      this.closeMenu()
    }
  }
}
</script>

<style lang="scss" scoped>
.usermenu {
  position: relative;

  &-trigger {
    padding: 0.5rem;
    border-radius: 999rem;
    cursor: pointer;

    &:hover {
      background: rgba(#000, 0.08);
    }
  }

  &-content {
    position: absolute;
    left: calc(100% + 1.5rem);
    bottom: 0;
    width: 15rem;
    width: min(20rem, 90vw);
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    box-shadow: 0 0 8px rgba(#000, 0.1);
    z-index: 1;

    &-items {
      display: flex;
      flex-flow: column nowrap;
      padding: 0.5rem 0;

      &-item {
        padding: 0.5rem 1rem;
        display: flex;
        flex-flow: row nowrap;
        gap: 0.5rem;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: rgba(#000, 0.08);
        }

        &-icon {
          height: 1.2rem;
        }
      }
    }

    &-footer {
      padding: 0.75rem 1rem;
      border-top: 1px solid rgba(#000, 0.08);
      display: flex;
      flex-flow: column nowrap;
      color: #60666b;
    }
  }
}

.bold {
  font-weight: 600;
  color: #303032;
}
</style>
