<template>
  <div
    v-if="showKP"
    id="knowledgepanel"
    :class="{ kpanel: true, 'kpanel-logo': KPLogoVisible }"
  >
    <div class="kpanel-related">
      <div class="kpanel-title">
        <p class="kpanel-title-text">{{ panelSubjectTitle }}</p>
        <p class="kpanel-title-subtext">{{ panelFacetTitle }}</p>
      </div>
      <div
        v-for="facet in relatedFacets"
        :key="facet"
        :class="{
          'kpanel-related-block': true,
          'similar-blocks-border': altBehaviourInfo(facet).line
        }"
      >
        <p :id="relatedFacetId[facet]" class="kpanel-related-block-subtitle">
          {{ relatedFacetSubtitles[facet] }}
        </p>
        <!-- Organisations block -->
        <div
          v-if="facet === 'organisations'"
          class="kpanel-related-block-organisations"
        >
          <div
            v-for="entry in organisationsInfoCropped"
            :key="entry.name"
            class="kpanel-related-block-organisations-entry"
            @click="enableFilter(entry, 'organisations', entry.replaceQuery)"
          >
            <img
              v-if="entry.logo && !noLogo.includes(entry.name)"
              :src="entry.logo"
              alt=""
              class="kpanel-related-block-organisations-entry-image"
              @error="noLogo.push(entry.name)"
            />
            <p
              v-else
              class="kpanel-related-block-organisations-entry-image"
              :style="`background: ${entry.color};`"
            >
              {{ entry.initials }}
            </p>
            <p class="kpanel-related-block-organisations-entry-text">
              {{ entry.name }}
            </p>
            <img
              v-if="altBehaviourInfo(facet).altIcon || entry.replaceQuery"
              src="@/assets/icons/search-medium.svg"
              alt=""
              class="kpanel-related-block-organisations-entry-icon"
            />
          </div>
          <p
            v-if="organisationsInfo.length > 3"
            class="showmore"
            @click="showMore.organisations = !showMore.organisations"
          >
            {{
              showMore.organisations
                ? 'Show less'
                : `Show ${organisationsInfo.length - 3} more`
            }}
          </p>
        </div>
        <!-- Authors block -->
        <div v-if="facet === 'authors'" class="kpanel-related-block-authors">
          <div
            v-for="author in authorsInfoCropped"
            :key="author.name"
            :class="{
              'kpanel-related-block-authors-entry': true,
              toggled: filterFromRoute('authors').includes(author.email)
            }"
            @click="
              !filterFromRoute('authors').includes(author.email) &&
                enableFilter(author, 'authors', false)
            "
          >
            <img
              v-if="author.avatar && !noAvatar.includes(author.name)"
              :src="author.avatar"
              alt=""
              class="kpanel-related-block-authors-entry-image"
              @error="noAvatar.push(author.name)"
            />
            <p
              v-else
              class="kpanel-related-block-authors-entry-image"
              :style="`background: ${author.color};`"
            >
              {{ author.initials }}
            </p>
            <p class="kpanel-related-block-authors-entry-text">
              {{ author.name }}
            </p>
            <b-tooltip
              v-if="author.email"
              type="is-dark"
              :label="`Copy email address: ${author.email}`"
            >
              <img
                src="@/assets/icons/mail.svg"
                alt=""
                class="kpanel-related-block-authors-entry-icon"
                @click.stop="copyEmail(author.email)"
              />
            </b-tooltip>
            <b-tooltip v-if="author.uuid" type="is-dark" label="Go to profile">
              <img
                src="@/assets/icons/user.svg"
                alt=""
                class="kpanel-related-block-authors-entry-icon"
                @click.stop="goToProfile(author.uuid)"
              />
            </b-tooltip>
          </div>
          <p
            v-if="authorsInfo.length > 3"
            class="showmore"
            @click="showMore.authors = !showMore.authors"
          >
            {{
              showMore.authors
                ? 'Show less'
                : `Show ${authorsInfo.length - 3} more`
            }}
          </p>
        </div>
        <!-- Topics block -->
        <div v-if="facet === 'topics'" class="kpanel-related-block-topics">
          <div
            v-for="entry in topicsInfoCropped"
            :key="entry.name"
            class="kpanel-related-block-topics-entry"
            @click="enableFilter(entry, 'topics')"
          >
            <p class="kpanel-related-block-topics-entry-text">
              {{ entry.name }}
            </p>
            <img
              v-if="altBehaviourInfo(facet).altIcon"
              src="@/assets/icons/search-medium.svg"
              alt=""
              class="kpanel-related-block-topics-entry-icon"
            />
          </div>
          <p
            v-if="topicsInfo.length > 5"
            class="showmore"
            @click="showMore.topics = !showMore.topics"
          >
            {{
              showMore.topics
                ? 'Show less'
                : `Show ${topicsInfo.length - 5} more`
            }}
          </p>
        </div>
        <!-- Industries block -->
        <div
          v-if="facet === 'industries'"
          class="kpanel-related-block-industries"
        >
          <div
            v-for="entry in industriesInfoCropped"
            :key="entry.name"
            class="kpanel-related-block-industries-entry"
            @click="enableFilter(entry, 'industries', entry.replaceQuery)"
          >
            <p class="kpanel-related-block-industries-entry-text">
              {{ entry.name }}
            </p>
            <img
              v-if="altBehaviourInfo(facet).altIcon"
              src="@/assets/icons/search-medium.svg"
              alt=""
              class="kpanel-related-block-topics-entry-icon"
            />
          </div>
          <p
            v-if="industriesInfo.length > 5"
            class="showmore"
            @click="showMore.industries = !showMore.industries"
          >
            {{
              showMore.industries
                ? 'Show less'
                : `Show ${industriesInfo.length - 5} more`
            }}
          </p>
        </div>
        <!-- Content classes block -->
        <div
          v-if="facet === 'content_classes'"
          class="kpanel-related-block-contentclasses"
        >
          <div
            v-for="entry in contentClassesInfoCropped"
            :key="entry"
            class="kpanel-related-block-contentclasses-entry"
            @click="enableFilter(entry, 'content_classes')"
          >
            {{ entry }}
          </div>
          <p
            v-if="contentClassesInfo.length > 5"
            class="showmore"
            @click="showMore.content_classes = !showMore.content_classes"
          >
            {{
              showMore.content_classes
                ? 'Show less'
                : `Show ${contentClassesInfo.length - 5} more`
            }}
          </p>
        </div>
      </div>
    </div>
    <img
      v-if="KPLogoVisible"
      :src="panelSubjectsLogo"
      alt=""
      class="kpanel-title-logo"
      @error="noLogo.push(panelSubjectTitle)"
    />
  </div>
</template>

<script>
import {
  searchDetail,
  searchSourcePage,
  searchTrigger
} from '@/services/searchEnrichmentService'
import { filterFromRoute, routeSafe } from '@/util'
import { isEmpty, orderBy } from 'lodash'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'KnowledgePanel',
  props: {
    entities: {
      type: Object,
      required: true
    },
    insights: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showMore: {
      organisations: false,
      authors: false,
      topics: false,
      industries: false,
      content_classes: false
    },
    facetMapping: {
      organisation: 'organisations',
      industry: 'industries',
      topic: 'topics'
    },
    showPanel: false,
    noLogo: [],
    noAvatar: []
  }),
  computed: {
    ...mapState({
      contentClasses: (state) => state.filters.contentClasses,
      activeFilters: (state) => state.filters.activeFilters
    }),
    panelSubjects() {
      const subjectInfo = Object.keys(this.insights.entities || []).map(
        (type) => ({
          ...this.insights.entities[type],
          type: this.facetMapping[type]
        })
      )
      if (subjectInfo.some((el) => isEmpty(el))) return []
      return orderBy(subjectInfo, 'type', 'desc')
    },
    matchCount() {
      return this.insights.count || 0
    },
    relatedFacets() {
      const facetsToShow = {
        organisations: [
          'authors',
          'topics',
          'content_classes',
          'organisations',
          'industries'
        ],
        industries: ['authors', 'topics', 'organisations', 'content_classes'],
        topics: [
          'authors',
          'industries',
          'organisations',
          'content_classes',
          'topics'
        ]
      }
      if (!(this.panelSubjects.length === 1 || this.panelSubjects.length === 2))
        return []
      let facets = []
      if (this.panelSubjects.length === 1)
        facets = facetsToShow[this.panelSubjects[0].type]
      else if (this.panelSubjects.length === 2)
        facets = ['authors', 'organisations', 'content_classes']
      return facets.filter(
        (facet) =>
          (facet === 'content_classes'
            ? this.contentClassesInfo
            : this[`${facet}Info`]
          ).length > 0
      )
    },
    relatedFacetSubtitles() {
      return {
        authors: this.altBehaviourInfo('authors').altIcon
          ? `People related to ${this.panelSubjectTitle}`
          : 'People who can help',
        organisations: this.altBehaviourInfo('organisations').altIcon
          ? this.panelSubjects.length === 1
            ? `Customers related to ${this.panelSubjectTitle}`
            : 'Other customers we worked for'
          : 'Top customers we worked for',
        industries: this.altBehaviourInfo('industries').altIcon
          ? `Industries related to ${this.panelSubjectTitle}`
          : 'Industries we worked in',
        content_classes: this.altBehaviourInfo('content_classes').altIcon
          ? `Content classes related to ${this.panelSubjectTitle}`
          : 'Content classes',
        topics: this.altBehaviourInfo('topics').altIcon
          ? `Topics related to ${this.panelSubjectTitle}`
          : 'Key topics we worked on'
      }
    },
    relatedFacetId() {
      return {
        authors: this.altBehaviourInfo('authors').altIcon
          ? 'peoplerelatedto'
          : 'peoplewhocanhelp',
        organisations:
          this.panelSubjects.length === 1
            ? 'customersrelatedto'
            : 'customersweworkedfor',
        industries: this.altBehaviourInfo('industries').altIcon
          ? 'industriesrelatedto'
          : 'industriesweworkedin',
        content_classes: 'contentclasses',
        topics: this.altBehaviourInfo('topics').altIcon
          ? 'topicsrelatedto'
          : 'topicsweworkedon'
      }
    },
    sameFacetsInPanel() {
      return this.panelSubjects.some((subject) =>
        this.relatedFacets.includes(subject.type)
      )
    },
    panelSubjectTitle() {
      return this.panelSubjects
        .map((subject) => subject.name)
        .join(
          this.panelSubjects.some((subject) => subject.type === 'organisations')
            ? ' at '
            : ' in '
        )
    },
    panelSubjectsLogo() {
      return this.panelSubjects.reduce(
        (prev, subject) => subject.logo || prev || '',
        ''
      )
    },
    panelFacetTitle() {
      const reverseFacetMapping = {
        organisations: 'organisation',
        industries: 'industry',
        topics: 'topic'
      }
      return (
        this.panelSubjects
          ?.map((subject) =>
            this.firstInUpperCase(reverseFacetMapping[subject.type])
          )
          ?.join(' & ') || ''
      )
    },
    organisationsInfo() {
      const orgs = (this.insights.organisations || [])
        .filter((el) =>
          this.panelSubjects.every((subject) => subject.uuid !== el.uuid)
        )
        .map((org) => {
          return {
            name: org.name,
            logo: org.logo,
            initials: this.$umodel.initials(org.name),
            color: this.$umodel.user_color({
              organisation: org.name,
              uuid: org.uuid
            })
          }
        })
      return orgs
    },
    organisationsInfoCropped() {
      return this.organisationsInfo.length > 3 && !this.showMore.organisations
        ? this.organisationsInfo.slice(0, 3)
        : this.organisationsInfo
    },
    authorsInfo() {
      const toggledAuthors = filterFromRoute(this.$route, 'authors')
      return (this.insights.authors || [])
        .filter((el) =>
          this.panelSubjects.every((subject) => subject.uuid !== el.uuid)
        )
        .map((author) => {
          return {
            name: author.name,
            email: author.email,
            avatar: author.avatar,
            initials: this.$umodel.initials(author.name),
            color: this.$umodel.user_color({
              username: author.name,
              uuid: author.uuid
            }),
            toggled: toggledAuthors.includes(author.email),
            uuid: author.uuid
          }
        })
    },
    authorsInfoCropped() {
      return this.authorsInfo.length > 3 && !this.showMore.authors
        ? this.authorsInfo.slice(0, 3)
        : this.authorsInfo
    },
    topicsInfo() {
      return (
        this.insights?.topics?.filter((el) =>
          this.panelSubjects.every((subject) => subject.uuid !== el.uuid)
        ) || []
      )
    },
    topicsInfoCropped() {
      return this.topicsInfo.length > 5 && !this.showMore.topics
        ? this.topicsInfo.slice(0, 5)
        : this.topicsInfo
    },
    industriesInfo() {
      return (
        this.insights?.industries?.filter((el) =>
          this.panelSubjects.every((subject) => subject.uuid !== el.uuid)
        ) || []
      ).map((el) => ({
        ...el,
        replaceQuery: this.panelSubjects.some(
          (subject) => subject.type === 'organisations'
        )
      }))
    },
    industriesInfoCropped() {
      return this.industriesInfo.length > 5 && !this.showMore.industries
        ? this.industriesInfo.slice(0, 5)
        : this.industriesInfo
    },
    contentClassesInfo() {
      const ccsInFacets = ['case', 'credential', 'references']
      const ccsFromFacets = Object.keys(this.contentClasses).filter((cc) =>
        ccsInFacets.some((el) => cc.toLowerCase().includes(el))
      )
      const ccsFromInsights = Object.keys(this.insights.content_classes)
        .filter(
          (cc) => !filterFromRoute(this.$route, 'content_classes').includes(cc)
        )
        .filter(
          (cc) => !ccsInFacets.some((el) => cc.toLowerCase().includes(el))
        )
      let ccs = [...ccsFromFacets, ...ccsFromInsights]
      ccs.sort((a, b) => {
        return ['proposal', ...ccsInFacets].some((val) => a.includes(val))
          ? -1
          : ['proposal', ...ccsInFacets].some((val) => b.includes(val))
          ? 1
          : 0
      })
      return ccs
    },
    contentClassesInfoCropped() {
      return this.contentClassesInfo.length > 5 &&
        !this.showMore.content_classes
        ? this.contentClassesInfo.slice(0, 5)
        : this.contentClassesInfo
    },
    routeQuery() {
      return this.$route.query
    },
    showKP() {
      return this.panelSubjects.length && this.matchCount > 0
    },
    KPLogoVisible() {
      return (
        this.panelSubjectsLogo && !this.noLogo.includes(this.panelSubjectTitle)
      )
    }
  },
  methods: {
    ...mapActions(['setSearchEnrichmentContext', 'restartBreadCrumbs']),
    enableFilter(topic, type, replaceQuery = false) {
      const topicVal = type === 'authors' ? topic.email : topic.name || topic
      let newQuery = { ...this.$route.query }
      let searchEnrichmentDetail = ''
      if (
        type === 'organisations' &&
        this.panelSubjects.length === 2 &&
        this.panelSubjects.every(
          (subject) =>
            subject.type === 'organisations' || subject.type === 'topics'
        )
      ) {
        searchEnrichmentDetail = searchDetail.knowledgepanel_insight.broader
        if (!isEmpty(newQuery.organisations || {})) {
          // Topic in search query => replace organisation in filters
          newQuery.organisations = routeSafe(topicVal)
        } else if (Object.keys(this.entities).length === 1) {
          // Topic in filters => replace organisation in search query
          newQuery.query = topicVal
        } else {
          // Both topic & org in search query => put only topic in query, put org in filter
          const topicIdx = Object.keys(this.entities).findIndex(
            (key) => this.entities[key].type === 'topic'
          )
          if (topicIdx === -1) return
          newQuery.query = Object.keys(this.entities)[topicIdx]
          newQuery.organisations = routeSafe(topicVal)
        }
      } else if (
        this.panelSubjects.some((el) => el.type === type) ||
        replaceQuery
      ) {
        newQuery = { query: topicVal }
        searchEnrichmentDetail = searchDetail.knowledgepanel_insight.similar
      } else {
        newQuery = {
          ...this.$route.query,
          [type]: this.$route.query[type]
            ? [...this.$route.query[type].split(','), routeSafe(topicVal)].join(
                ','
              )
            : routeSafe(topicVal)
        }
        searchEnrichmentDetail = searchDetail.knowledgepanel_insight.deeper
      }
      this.setSearchEnrichmentContext({
        page: searchSourcePage.search_results,
        trigger: searchTrigger.knowledgepanel_insight,
        detail: searchEnrichmentDetail
      })
      let { view, ...filteredQuery } = newQuery
      if (view && view === 'all') {
        filteredQuery.view = 'all'
      }
      const newRoute = {
        ...this.$route,
        query: filteredQuery
      }
      this.$router.push(newRoute)
    },
    firstInUpperCase(text) {
      return text[0].toUpperCase() + text.slice(1).toLowerCase()
    },
    altBehaviourInfo(facet) {
      return this.panelSubjects.some((subject) => subject.type === facet)
        ? {
            line: true,
            altIcon: true
          }
        : facet === 'industries' &&
          this.panelSubjects.some((subject) => subject.type === 'organisations')
        ? {
            line:
              !this.relatedFacets.includes('organisations') ||
              this.organisationsInfoCropped.length === 0,
            altIcon: true
          }
        : {
            line: false,
            altIcon: false
          }
    },
    copyEmail(email) {
      navigator.clipboard.writeText(email)
      this.$toast.success('Email address copied')
    },
    filterFromRoute(key) {
      return filterFromRoute(this.$route, key)
    },
    goToProfile(uuid) {
      this.restartBreadCrumbs({ ...this.$route })
      this.$router.push({
        name: 'member-profile',
        params: {
          workspace_id: this.$route.params.workspace_id,
          member_id: uuid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.kpanel {
  width: 100%;
  height: max-content;
  background: #f9f9fa;
  border-radius: 4px;
  border: 1px solid rgba(#000, 8%);
  padding: 2rem 1.5rem 2rem 1.5rem;

  display: grid;
  gap: 0.5rem;

  &:not(.kpanel-logo) {
    grid-template-columns: 100%;
  }

  &-logo {
    grid-template-columns: 80% fit-content(6rem);
  }

  position: relative;

  &-title {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 0.5rem;

    &-logo {
      height: 4rem;
      max-width: 6rem;
      border-radius: 4px;
      border: 2px solid rgba(#000, 16%);
    }

    &-text {
      font-size: 1.3rem;
      font-weight: 700;
    }

    &-subtext {
      font-size: 0.9rem;
      color: #60666b;
    }
  }

  &-related {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: 1.5rem;

    &-block {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;

      &-subtitle {
        font-size: 1.1rem;
        font-weight: 600;
        padding-bottom: 0.25rem;
      }

      &-organisations {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.25rem;

        &-entry {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;
          padding: 6px;
          border-radius: 4px;
          cursor: pointer;
          width: fit-content;
          max-width: 100%;
          white-space: nowrap;

          &:hover {
            background: rgba(#000, 8%);
          }

          &-text {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &-image {
            height: 2rem;
            width: 2rem;
            min-width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            color: white;
            font-weight: 700;
            overflow: hidden;
          }

          &-icon {
            height: 1rem;
            width: 1rem;
            min-width: 1rem;
            filter: invert(65%) sepia(8%) saturate(235%) hue-rotate(177deg)
              brightness(89%) contrast(83%);
          }
        }
      }

      &-authors {
        display: flex;
        flex-flow: column nowrap;
        gap: 0.5rem;

        &-entry {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          gap: 0.5rem;
          padding: 6px;
          border-radius: 4px;
          cursor: pointer;
          width: fit-content;
          max-width: 100%;
          white-space: nowrap;

          &:hover {
            background: rgba(#000, 8%);

            &.toggled {
              background: transparent;
              cursor: default;
            }
          }

          &-text {
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &-image {
            height: 2rem;
            width: 2rem;
            min-width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            color: white;
            font-weight: 700;
            overflow: hidden;
          }

          &-icon {
            height: 1.5rem;
            width: 1.5rem;
            min-width: 1.5rem;
            filter: invert(65%) sepia(8%) saturate(235%) hue-rotate(177deg)
              brightness(89%) contrast(83%);
            padding: 0.25rem;
            border-radius: 100%;
            cursor: pointer;
            transform: translateY(3px);

            &:hover {
              background: rgba(#000, 16%);
            }
          }
        }
      }

      &-topics {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;

        &-entry {
          padding: 0.3rem 1rem;
          border-radius: 6px;
          background: #f1f2f3;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          max-width: 100%;

          &:hover {
            background: #ebebeb;
          }

          &-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #8f9399;
            font-weight: 600;
          }

          &-icon {
            height: 1rem;
            filter: invert(65%) sepia(8%) saturate(235%) hue-rotate(177deg)
              brightness(89%) contrast(83%);
          }
        }
      }

      &-industries {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;

        &-entry {
          padding: 0.3rem 1rem;
          border-radius: 6px;
          background: #f1f2f3;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          max-width: 100%;

          &:hover {
            background: #ebebeb;
          }

          &-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #8f9399;
            font-weight: 600;
          }

          &-icon {
            height: 1.5rem;
            filter: invert(65%) sepia(8%) saturate(235%) hue-rotate(177deg)
              brightness(89%) contrast(83%);
          }
        }
      }

      &-contentclasses {
        display: flex;
        flex-flow: row wrap;
        gap: 0.5rem;

        &-entry {
          padding: 0.3rem 1rem;
          border-radius: 6px;
          background: #f1f2f3;
          color: #8f9399;
          font-weight: 600;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            background: #ebebeb;
          }
        }
      }
    }
  }
}

.showmore {
  color: #8f9399;
  text-decoration: underline;
  cursor: pointer;
  width: 100%;
}

.similar-blocks-border {
  padding-top: 1rem;
  border-top: 1px solid rgba(#000, 8%);
}
</style>
