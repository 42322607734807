<template>
  <div ref="impersonationmenu" class="impersonate">
    <b-tooltip
      v-if="impersonatedMember"
      :active="!visible"
      :label="`Impersonating ${$umodel.full_name(impersonatedMember)}`"
      position="is-right"
      type="is-dark"
    >
      <div class="impersonate-trigger" @click="toggleMenu">
        <Avatar
          :user="impersonatedMember"
          fallback-icon="user"
          :filter-fallback="{}"
          size="xs"
        />
      </div>
    </b-tooltip>
    <div v-if="visible" class="impersonate-content">
      <div class="impersonate-content-header">
        <p class="impersonate-content-header-title">Impersonating</p>
        <div class="impersonate-content-header-avatar">
          <Avatar
            :user="impersonatedMember"
            fallback-icon="user"
            :filter-fallback="{}"
            size="s"
          />
          {{ $umodel.full_name(impersonatedMember) }}
          <span class="impersonate-content-header-avatar-email">{{
            $umodel.email(impersonatedMember)
          }}</span>
        </div>
      </div>
      <div class="impersonate-content-actions">
        <Button text="Change user" type="white" @click="openModal" />
        <Button
          text="Stop impersonation"
          @click="() => impersonate(undefined)"
        />
      </div>
    </div>
    <b-modal v-model="showModal">
      <div class="impersonate-modal">
        <div class="impersonate-modal-header">
          Change impersonated user
          <Button icon="close" type="grey" @click="closeModal" />
        </div>
        <div class="impersonate-modal-content">
          <TextInput
            v-model="query"
            left-icon="search"
            placeholder="Find member to impersonate"
            @submit="search"
          />
          <div class="impersonate-modal-content-list">
            <div
              v-for="option in options"
              :key="option.uuid"
              class="impersonate-modal-content-list-option"
            >
              <Avatar :user="option" size="xs" />
              {{ $umodel.full_name(option) }}
              <Button
                text="Impersonate"
                class="impersonate-modal-content-list-option-button"
                :disabled="
                  (!!impersonatedMember &&
                    option.uuid === impersonatedMember.uuid) ||
                  (!!currentWorkspaceMember &&
                    option.uuid === currentWorkspaceMember.uuid)
                "
                @click="() => impersonate(option)"
              />
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { removeImpersonatedMember, setImpersonatedMember } from '@c/util'
import Avatar from '@c/library/Avatar.vue'
import Button from '@c/library/Button.vue'
import TextInput from '@c/library/TextInput.vue'

export default {
  name: 'ImpersonationMenu',
  components: {
    Avatar,
    Button,
    TextInput
  },
  data: () => ({
    visible: false,
    impersonatedMember: undefined,
    showModal: false,
    query: '',
    options: []
  }),
  computed: {
    ...mapGetters(['currentWorkspaceMember']),
    impersonationFromRoute() {
      return this.$route.query.act_as
    }
  },
  watch: {
    impersonationFromRoute: {
      immediate: true,
      handler() {
        this.loadImpersonatedMember()
      }
    }
  },
  methods: {
    ...mapActions(['getWorkspaceMember', 'getWorkspaceMembersPage']),
    async loadImpersonatedMember() {
      if (
        this.impersonationFromRoute &&
        this.impersonationFromRoute !== this.impersonatedMember?.uuid
      ) {
        try {
          this.impersonatedMember = await this.getWorkspaceMember({
            workspace_id: this.$route.params.workspace_id,
            member_id: this.impersonationFromRoute
          })
        } catch (e) {
          this.$console.debug('Error loading impersonated member', e)
          this.$toast.error(e, 'loading impersonated member')
        }
      } else if (!this.impersonationFromRoute) {
        this.impersonatedMember = undefined
      }
    },
    toggleMenu() {
      if (this.visible) this.closeMenu()
      else this.openMenu()
    },
    openMenu() {
      this.visible = !this.visible
      document.addEventListener('click', this.checkClickOutside)
    },
    checkClickOutside(e) {
      if (
        this.visible &&
        this.$refs.impersonationmenu &&
        !this.$refs.impersonationmenu.contains(e.target)
      ) {
        this.closeMenu()
      }
    },
    closeMenu() {
      this.visible = false
      document.removeEventListener('click', this.checkClickOutside)
    },
    impersonate(user) {
      if (user) {
        setImpersonatedMember(user, this.$route, this.$router)
        this.closeModal()
      } else {
        removeImpersonatedMember(this.$route, this.$router)
        this.closeMenu()
      }
    },
    openModal() {
      this.closeMenu()
      this.showModal = true
      this.search()
    },
    closeModal() {
      this.showModal = false
      this.query = ''
      this.options = []
    },
    async search() {
      try {
        this.options = await this.getWorkspaceMembersPage({
          workspace_id: this.$route.params.workspace_id,
          params: this.query ? { search: this.query } : {}
        })
      } catch (e) {
        this.$console.debug('Error searching for user', e)
        this.$toast.error(e, 'searching for this user')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.impersonate {
  position: relative;

  &-trigger {
    padding: 0.25rem;
    border-radius: 999rem;
    cursor: pointer;
    border: 1px dashed $primary;

    &:hover {
      background: rgba(#000, 0.04);
    }
  }

  &-content {
    position: absolute;
    left: calc(100% + 1.5rem);
    bottom: 0;
    width: 15rem;
    width: min(35rem, 90vw);
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    box-shadow: 0 0 8px rgba(#000, 0.1);
    z-index: 1;

    &-header {
      padding: 1rem;
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;

      &-title {
        font-weight: 600;
        font-size: 1.25rem;
      }

      &-avatar {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem;
        border-radius: 4px;
        background: rgba($primary, 0.08);

        &-email {
          color: #60666b;
          margin-left: auto;
        }
      }
    }

    &-actions {
      padding: 0.75rem 1rem;
      border-top: 1px solid rgba(#000, 0.08);
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  &-modal {
    width: min(45rem, 90vw);
    background: white;
    border-radius: 6px;

    &-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
      font-size: 1.5rem;
      padding: 1rem 1.5rem;
      font-weight: 600;
      border-bottom: 1px solid rgba(#000, 0.08);
    }

    &-content {
      padding: 1rem 1.5rem;

      &-list {
        padding: 0.5rem 0;
        max-height: 50vh;
        overflow-y: auto;

        &-option {
          padding: 0.5rem 1rem;
          display: flex;
          flex-flow: row nowrap;
          gap: 0.5rem;
          align-items: center;
          border-radius: 4px;

          &:hover {
            background: rgba(#000, 0.08);
          }

          &-button {
            margin-left: auto;
          }
        }
      }
    }
  }
}

.bold {
  font-weight: 600;
  color: #303032;
}

::v-deep .modal-content {
  width: unset !important;
}
</style>
