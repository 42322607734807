import { render, staticRenderFns } from "./KnowledgePanel.vue?vue&type=template&id=237a07d1&scoped=true&"
import script from "./KnowledgePanel.vue?vue&type=script&lang=js&"
export * from "./KnowledgePanel.vue?vue&type=script&lang=js&"
import style0 from "./KnowledgePanel.vue?vue&type=style&index=0&id=237a07d1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237a07d1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/frontend/frontend/src/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('237a07d1')) {
      api.createRecord('237a07d1', component.options)
    } else {
      api.reload('237a07d1', component.options)
    }
    module.hot.accept("./KnowledgePanel.vue?vue&type=template&id=237a07d1&scoped=true&", function () {
      api.rerender('237a07d1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views-v2/KnowledgePanel.vue"
export default component.exports