var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "document-type-tabs border subtle-primary no-top no-horizontal thick",
      class: { unclickable: _vm.sendingEvent }
    },
    [
      _c(
        "b-tabs",
        {
          staticClass: "block",
          attrs: { position: "is-left" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("b-tab-item", {
            attrs: { value: "all" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "tab-wrapper",
                        attrs: { id: "allresultstab" }
                      },
                      [
                        _c("img", {
                          staticClass: "tab-icon",
                          attrs: { src: require("@/assets/icons/search.svg") }
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "has-text-weight-bold has-text-black"
                          },
                          [_vm._v("All results")]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("b-tab-item", {
            attrs: { value: "documents" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "tab-wrapper", attrs: { id: "docstab" } },
                      [
                        _c("img", {
                          staticClass: "tab-icon",
                          attrs: { src: require("@/assets/icons/document.svg") }
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "has-text-weight-bold has-text-black"
                          },
                          [_vm._v("Docs")]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("b-tab-item", {
            attrs: { value: "sheets" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "tab-wrapper",
                        attrs: { id: "sheetstab" }
                      },
                      [
                        _c("img", {
                          staticClass: "tab-icon",
                          attrs: { src: require("@/assets/icons/sheet.svg") }
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "has-text-weight-bold has-text-black"
                          },
                          [_vm._v("Sheets")]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("b-tab-item", {
            attrs: { value: "presentations" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "tab-wrapper",
                        attrs: { id: "presentationstab" }
                      },
                      [
                        _c("img", {
                          staticClass: "tab-icon",
                          attrs: {
                            src: require("@/assets/icons/presentation.svg")
                          }
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "has-text-weight-bold has-text-black"
                          },
                          [_vm._v(" Presentations ")]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("b-tab-item", {
            attrs: { value: "slides" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "tab-wrapper",
                        attrs: { id: "slidestab" }
                      },
                      [
                        _c("img", {
                          staticClass: "tab-icon",
                          attrs: { src: require("@/assets/icons/slides.svg") }
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "has-text-weight-bold has-text-black"
                          },
                          [_vm._v("Slides")]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c("b-tab-item", {
            attrs: { value: "videos" },
            scopedSlots: _vm._u([
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "tab-wrapper", attrs: { id: "videotab" } },
                      [
                        _c("img", {
                          staticClass: "tab-icon",
                          attrs: { src: require("@/assets/icons/video.svg") }
                        }),
                        _c(
                          "span",
                          {
                            staticClass: "has-text-weight-bold has-text-black"
                          },
                          [_vm._v("Videos")]
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          _vm.showPeopleTab
            ? _c("b-tab-item", {
                attrs: { value: "people" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "tab-wrapper",
                              attrs: { id: "peopletab" }
                            },
                            [
                              _c("img", {
                                staticClass: "tab-icon",
                                attrs: {
                                  src: require("@/assets/icons/users.svg")
                                }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "has-text-weight-bold has-text-black"
                                },
                                [_vm._v("People")]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2340744056
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }