var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showKP
    ? _c(
        "div",
        {
          class: { kpanel: true, "kpanel-logo": _vm.KPLogoVisible },
          attrs: { id: "knowledgepanel" }
        },
        [
          _c(
            "div",
            { staticClass: "kpanel-related" },
            [
              _c("div", { staticClass: "kpanel-title" }, [
                _c("p", { staticClass: "kpanel-title-text" }, [
                  _vm._v(_vm._s(_vm.panelSubjectTitle))
                ]),
                _c("p", { staticClass: "kpanel-title-subtext" }, [
                  _vm._v(_vm._s(_vm.panelFacetTitle))
                ])
              ]),
              _vm._l(_vm.relatedFacets, function(facet) {
                return _c(
                  "div",
                  {
                    key: facet,
                    class: {
                      "kpanel-related-block": true,
                      "similar-blocks-border": _vm.altBehaviourInfo(facet).line
                    }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "kpanel-related-block-subtitle",
                        attrs: { id: _vm.relatedFacetId[facet] }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.relatedFacetSubtitles[facet]) + " "
                        )
                      ]
                    ),
                    facet === "organisations"
                      ? _c(
                          "div",
                          { staticClass: "kpanel-related-block-organisations" },
                          [
                            _vm._l(_vm.organisationsInfoCropped, function(
                              entry
                            ) {
                              return _c(
                                "div",
                                {
                                  key: entry.name,
                                  staticClass:
                                    "kpanel-related-block-organisations-entry",
                                  on: {
                                    click: function($event) {
                                      return _vm.enableFilter(
                                        entry,
                                        "organisations",
                                        entry.replaceQuery
                                      )
                                    }
                                  }
                                },
                                [
                                  entry.logo && !_vm.noLogo.includes(entry.name)
                                    ? _c("img", {
                                        staticClass:
                                          "kpanel-related-block-organisations-entry-image",
                                        attrs: { src: entry.logo, alt: "" },
                                        on: {
                                          error: function($event) {
                                            return _vm.noLogo.push(entry.name)
                                          }
                                        }
                                      })
                                    : _c(
                                        "p",
                                        {
                                          staticClass:
                                            "kpanel-related-block-organisations-entry-image",
                                          style:
                                            "background: " + entry.color + ";"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(entry.initials) + " "
                                          )
                                        ]
                                      ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "kpanel-related-block-organisations-entry-text"
                                    },
                                    [_vm._v(" " + _vm._s(entry.name) + " ")]
                                  ),
                                  _vm.altBehaviourInfo(facet).altIcon ||
                                  entry.replaceQuery
                                    ? _c("img", {
                                        staticClass:
                                          "kpanel-related-block-organisations-entry-icon",
                                        attrs: {
                                          src: require("@/assets/icons/search-medium.svg"),
                                          alt: ""
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            }),
                            _vm.organisationsInfo.length > 3
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "showmore",
                                    on: {
                                      click: function($event) {
                                        _vm.showMore.organisations = !_vm
                                          .showMore.organisations
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showMore.organisations
                                            ? "Show less"
                                            : "Show " +
                                                (_vm.organisationsInfo.length -
                                                  3) +
                                                " more"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    facet === "authors"
                      ? _c(
                          "div",
                          { staticClass: "kpanel-related-block-authors" },
                          [
                            _vm._l(_vm.authorsInfoCropped, function(author) {
                              return _c(
                                "div",
                                {
                                  key: author.name,
                                  class: {
                                    "kpanel-related-block-authors-entry": true,
                                    toggled: _vm
                                      .filterFromRoute("authors")
                                      .includes(author.email)
                                  },
                                  on: {
                                    click: function($event) {
                                      !_vm
                                        .filterFromRoute("authors")
                                        .includes(author.email) &&
                                        _vm.enableFilter(
                                          author,
                                          "authors",
                                          false
                                        )
                                    }
                                  }
                                },
                                [
                                  author.avatar &&
                                  !_vm.noAvatar.includes(author.name)
                                    ? _c("img", {
                                        staticClass:
                                          "kpanel-related-block-authors-entry-image",
                                        attrs: { src: author.avatar, alt: "" },
                                        on: {
                                          error: function($event) {
                                            return _vm.noAvatar.push(
                                              author.name
                                            )
                                          }
                                        }
                                      })
                                    : _c(
                                        "p",
                                        {
                                          staticClass:
                                            "kpanel-related-block-authors-entry-image",
                                          style:
                                            "background: " + author.color + ";"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(author.initials) + " "
                                          )
                                        ]
                                      ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "kpanel-related-block-authors-entry-text"
                                    },
                                    [_vm._v(" " + _vm._s(author.name) + " ")]
                                  ),
                                  author.email
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-dark",
                                            label:
                                              "Copy email address: " +
                                              author.email
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "kpanel-related-block-authors-entry-icon",
                                            attrs: {
                                              src: require("@/assets/icons/mail.svg"),
                                              alt: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.copyEmail(
                                                  author.email
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e(),
                                  author.uuid
                                    ? _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            type: "is-dark",
                                            label: "Go to profile"
                                          }
                                        },
                                        [
                                          _c("img", {
                                            staticClass:
                                              "kpanel-related-block-authors-entry-icon",
                                            attrs: {
                                              src: require("@/assets/icons/user.svg"),
                                              alt: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.goToProfile(
                                                  author.uuid
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            _vm.authorsInfo.length > 3
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "showmore",
                                    on: {
                                      click: function($event) {
                                        _vm.showMore.authors = !_vm.showMore
                                          .authors
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showMore.authors
                                            ? "Show less"
                                            : "Show " +
                                                (_vm.authorsInfo.length - 3) +
                                                " more"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    facet === "topics"
                      ? _c(
                          "div",
                          { staticClass: "kpanel-related-block-topics" },
                          [
                            _vm._l(_vm.topicsInfoCropped, function(entry) {
                              return _c(
                                "div",
                                {
                                  key: entry.name,
                                  staticClass:
                                    "kpanel-related-block-topics-entry",
                                  on: {
                                    click: function($event) {
                                      return _vm.enableFilter(entry, "topics")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "kpanel-related-block-topics-entry-text"
                                    },
                                    [_vm._v(" " + _vm._s(entry.name) + " ")]
                                  ),
                                  _vm.altBehaviourInfo(facet).altIcon
                                    ? _c("img", {
                                        staticClass:
                                          "kpanel-related-block-topics-entry-icon",
                                        attrs: {
                                          src: require("@/assets/icons/search-medium.svg"),
                                          alt: ""
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            }),
                            _vm.topicsInfo.length > 5
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "showmore",
                                    on: {
                                      click: function($event) {
                                        _vm.showMore.topics = !_vm.showMore
                                          .topics
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showMore.topics
                                            ? "Show less"
                                            : "Show " +
                                                (_vm.topicsInfo.length - 5) +
                                                " more"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    facet === "industries"
                      ? _c(
                          "div",
                          { staticClass: "kpanel-related-block-industries" },
                          [
                            _vm._l(_vm.industriesInfoCropped, function(entry) {
                              return _c(
                                "div",
                                {
                                  key: entry.name,
                                  staticClass:
                                    "kpanel-related-block-industries-entry",
                                  on: {
                                    click: function($event) {
                                      return _vm.enableFilter(
                                        entry,
                                        "industries",
                                        entry.replaceQuery
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "kpanel-related-block-industries-entry-text"
                                    },
                                    [_vm._v(" " + _vm._s(entry.name) + " ")]
                                  ),
                                  _vm.altBehaviourInfo(facet).altIcon
                                    ? _c("img", {
                                        staticClass:
                                          "kpanel-related-block-topics-entry-icon",
                                        attrs: {
                                          src: require("@/assets/icons/search-medium.svg"),
                                          alt: ""
                                        }
                                      })
                                    : _vm._e()
                                ]
                              )
                            }),
                            _vm.industriesInfo.length > 5
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "showmore",
                                    on: {
                                      click: function($event) {
                                        _vm.showMore.industries = !_vm.showMore
                                          .industries
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showMore.industries
                                            ? "Show less"
                                            : "Show " +
                                                (_vm.industriesInfo.length -
                                                  5) +
                                                " more"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    facet === "content_classes"
                      ? _c(
                          "div",
                          {
                            staticClass: "kpanel-related-block-contentclasses"
                          },
                          [
                            _vm._l(_vm.contentClassesInfoCropped, function(
                              entry
                            ) {
                              return _c(
                                "div",
                                {
                                  key: entry,
                                  staticClass:
                                    "kpanel-related-block-contentclasses-entry",
                                  on: {
                                    click: function($event) {
                                      return _vm.enableFilter(
                                        entry,
                                        "content_classes"
                                      )
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(entry) + " ")]
                              )
                            }),
                            _vm.contentClassesInfo.length > 5
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "showmore",
                                    on: {
                                      click: function($event) {
                                        _vm.showMore.content_classes = !_vm
                                          .showMore.content_classes
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.showMore.content_classes
                                            ? "Show less"
                                            : "Show " +
                                                (_vm.contentClassesInfo.length -
                                                  5) +
                                                " more"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              })
            ],
            2
          ),
          _vm.KPLogoVisible
            ? _c("img", {
                staticClass: "kpanel-title-logo",
                attrs: { src: _vm.panelSubjectsLogo, alt: "" },
                on: {
                  error: function($event) {
                    return _vm.noLogo.push(_vm.panelSubjectTitle)
                  }
                }
              })
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }