var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "usermenucontent", staticClass: "usermenu" }, [
    _c(
      "div",
      { staticClass: "usermenu-trigger", on: { click: _vm.toggleMenu } },
      [
        _c("Avatar", {
          attrs: {
            user: _vm.currentUser,
            "fallback-icon": "user",
            "filter-fallback": {},
            size: "s"
          }
        })
      ],
      1
    ),
    _vm.visible
      ? _c("div", { staticClass: "usermenu-content" }, [
          _c(
            "div",
            { staticClass: "usermenu-content-items" },
            [
              _c(
                "p",
                {
                  staticClass: "usermenu-content-items-item",
                  on: { click: _vm.gotoSettings }
                },
                [
                  _c("img", {
                    staticClass: "usermenu-content-items-item-icon",
                    attrs: {
                      src: require("@/assets/icons/settings.svg"),
                      alt: ""
                    }
                  }),
                  _vm._v(" My settings ")
                ]
              ),
              _c("router-link", { attrs: { to: _vm.profileRoute } }, [
                _c(
                  "p",
                  {
                    staticClass: "usermenu-content-items-item",
                    on: { click: _vm.closeMenu }
                  },
                  [
                    _c("img", {
                      staticClass: "usermenu-content-items-item-icon",
                      attrs: {
                        src: require("@/assets/icons/user.svg"),
                        alt: ""
                      }
                    }),
                    _vm._v(" My profile ")
                  ]
                )
              ]),
              _c(
                "p",
                {
                  staticClass: "usermenu-content-items-item",
                  on: { click: _vm.handleLogout }
                },
                [
                  _c("img", {
                    staticClass: "usermenu-content-items-item-icon",
                    attrs: {
                      src: require("@/assets/icons/logout.svg"),
                      alt: ""
                    }
                  }),
                  _vm._v(" Log out ")
                ]
              )
            ],
            1
          ),
          _c("div", { staticClass: "usermenu-content-footer" }, [
            _c("span", [
              _vm._v("Logged in as "),
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.$umodel.full_name(_vm.currentUser)))
              ])
            ]),
            _c("span", [_vm._v(_vm._s(_vm.$umodel.email(_vm.currentUser)))])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }