var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar" },
    [
      _c(
        "div",
        { staticClass: "sidebar-links" },
        [
          _vm._l(_vm.sidebarContent, function(item) {
            return _c(
              "b-tooltip",
              {
                key: item.label,
                attrs: {
                  label: item.label,
                  active: !_vm.showWorkspaceList,
                  position: "is-right",
                  type: "is-dark",
                  "append-to-body": ""
                }
              },
              [
                _c("router-link", { attrs: { to: item.route } }, [
                  _c(
                    "div",
                    {
                      staticClass: "sidebar-links-link",
                      class: {
                        selected: _vm.$route.name === item.routeName,
                        "is-home":
                          item.label === "Home" &&
                          _vm.currentWorkspace.image &&
                          !_vm.homeErrored &&
                          _vm.homeLoaded
                      },
                      on: {
                        contextmenu: function(e) {
                          return _vm.handleRightClick(item, e)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "sidebar-links-link-icon",
                        attrs: {
                          src:
                            item.label === "Home" &&
                            _vm.currentWorkspace.image &&
                            !_vm.homeErrored
                              ? _vm.currentWorkspace.image
                              : require("@/assets/icons/" + item.icon + ".svg"),
                          alt: ""
                        },
                        on: {
                          error: function() {
                            return _vm.handleError(item)
                          },
                          load: function() {
                            return _vm.handleLoad(item)
                          }
                        }
                      }),
                      item.badge
                        ? _c("p", { staticClass: "sidebar-links-link-badge" }, [
                            _vm._v(" " + _vm._s(item.badge) + " ")
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "sidebar-links-bottom" },
            [_vm.isUman ? _c("ImpersonationMenu") : _vm._e(), _c("UserMenu")],
            1
          )
        ],
        2
      ),
      _vm.showWorkspaceList
        ? _c("WorkspaceSelect", {
            on: {
              hide: function() {
                return (_vm.showWorkspaceList = false)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }