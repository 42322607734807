var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: (_vm.similarQueries || []).length > 0,
          expression: "(similarQueries || []).length > 0"
        }
      ],
      staticClass: "queries",
      attrs: { id: "otherpeoplesearchfor" }
    },
    [
      _c("p", { staticClass: "queries-title" }, [
        _vm._v("Other people search for")
      ]),
      _vm._l(_vm.similarQueries, function(query) {
        return _c(
          "router-link",
          { key: query, attrs: { to: _vm.route(query) } },
          [
            _c(
              "div",
              {
                staticClass: "queries-query",
                on: {
                  click: function() {
                    return _vm.enrichSearchContext()
                  }
                }
              },
              [_vm._v(" " + _vm._s(query) + " ")]
            )
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }