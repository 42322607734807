var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.workspaces && _vm.currentWorkspaceMember
    ? _c(
        "div",
        [
          _c(
            "DefaultApplicationShell",
            _vm._b(
              {
                attrs: {
                  "all-workspaces": _vm.populatedWorkspaces,
                  workspace: _vm.populatedActiveWorkspace,
                  "current-workspace-member": _vm.currentWorkspaceMember,
                  user: _vm.currentUser,
                  "search-results": _vm.searchResults,
                  "initial-active": _vm.activeFeedItem,
                  "current-page-workspace-member": _vm.activeMember
                },
                on: {
                  logout: _vm.logoutDialog,
                  workspaceChanged: _vm.workspaceChanged,
                  workspacePlus: function($event) {
                    return _vm.$router.push({
                      name: "workspace-list",
                      query: { return: true }
                    })
                  }
                }
              },
              "DefaultApplicationShell",
              Object.assign({}, _vm.$attrs),
              false
            ),
            [
              _c(
                "div",
                {
                  class: { "content-block": _vm.hasContentBlock },
                  style:
                    "height: " + (_vm.isHomePage ? "100%" : "fit-content") + ";"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "full-height",
                      class: { "pv-6": !_vm.noPadding, "ph-5": !_vm.noPadding }
                    },
                    [_vm._t("default")],
                    2
                  )
                ]
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }